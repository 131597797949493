<template  lang="pug" >
    div(class="full-height" style="background: rgb(0 57 96 / 3%);" )
        v-container(class="" )
            v-row(class="")
                v-col( cols="12")
                    v-card(outlined class="" )
                        v-card-text()
                            div(class="mt-8 mb-10" v-if="step == 'GO_TO_START' || step == ''")
                                v-row 
                                    v-col(offset-sm="4" cols="4")
                                        h2(class="text-h4  blue-500--text text-center mb-4") Motor de audiencias
                                        v-banner(color="blue-600") 
                                            p(class="text-body-2 text-center mt-2 mb-3 white--text") Este motor sirve para generar lógicas comerciales de alto impacto y su respectiva audiencia. <br>  Favor de seleccionar el medio.
                                v-row 
                                    v-col(offset-sm="4" cols="4") 
                                        div 
                                            v-divider(class="mb-6")
                                        div(class="mb-3")
                                            v-btn(color="primary" block small @click="selectStep(STEPS.GO_TO_EMAIL)") Email 
                                        div(class="mb-3")
                                            v-btn(color="primary"  block small @click="selectStep(STEPS.GO_TO_PUSH)") Push
                                        div(class="")
                                            v-btn(color="primary"  block small @click="selectStep(STEPS.GO_TO_COPPEL_PAY)" disabled) Coppel Pay

                            //- Pantallas del formulario
                            MedioSelectedEmail(v-else-if="step == STEPS.GO_TO_EMAIL" @onClickSelectStep="handleSelectStep")
                            MedioSelectedEmailComercial(v-else-if="step == STEPS.GO_TO_EMAIL_COMERCIAL" @onClickSelectStep="handleSelectStep")
                            MedioSelectedEmailOfertas(v-else-if="step == STEPS.GO_TO_EMAIL_OFERTAS" @onClickSelectStep="handleSelectStep")
                            MedioSelectedPush(v-else-if="step == STEPS.GO_TO_PUSH" @onClickSelectStep="handleSelectStep")
                            MedioSelectedPushCategoria(v-else-if="step == STEPS.GO_TO_PUSH_CATEGORIA" @onClickSelectStep="handleSelectStep")
                            MedioSelectedPushMulticategoria(v-else-if="step == STEPS.GO_TO_PUSH_MULTICATEGORIA" @onClickSelectStep="handleSelectStep")
                            MedioSelectedPushCorporateDeal(v-else-if="step == STEPS.GO_TO_PUSH_CORPORATE_DEAL" @onClickSelectStep="handleSelectStep")
                            MedioSelectedPushTemporadaAlta(v-else-if="step == STEPS.GO_TO_PUSH_TEMPORADA_ALTA" @onClickSelectStep="handleSelectStep")


</template>
<script>
import { mapGetters } from 'vuex'; 

export const STEPS = {
    GO_TO_START: 'GO_TO_START',
    GO_TO_EMAIL: 'GO_TO_EMAIL',
    GO_TO_COPPEL_PAY: 'GO_TO_COPPEL_PAY',
    GO_TO_EMAIL_OFERTAS: 'GO_TO_EMAIL_OFERTAS',
    GO_TO_EMAIL_COMERCIAL: 'GO_TO_EMAIL_COMERCIAL',
    GO_TO_PUSH: 'GO_TO_PUSH',
    GO_TO_PUSH_CATEGORIA: 'GO_TO_PUSH_CATEGORIA',
    GO_TO_PUSH_MULTICATEGORIA: 'GO_TO_PUSH_MULTICATEGORIA',
    GO_TO_PUSH_CORPORATE_DEAL: 'GO_TO_PUSH_CORPORATE_DEAL',
    GO_TO_PUSH_TEMPORADA_ALTA: 'GO_TO_PUSH_TEMPORADA_ALTA',
}
export default {
    name: 'MotorAudiencias',
    components: {
        'MedioSelectedEmail': () => import('./MedioSelectedEmail'),
        'MedioSelectedEmailComercial': () => import('./MedioSelectedEmailComercial'),
        'MedioSelectedEmailOfertas': () => import('./MedioSelectedEmailOfertas'),
        'MedioSelectedPush': () => import('./MedioSelectedPush'),
        'MedioSelectedPushCategoria': () => import('./MedioSelectedPushCategoria'),
        'MedioSelectedPushMulticategoria': () => import('./MedioSelectedPushMulticategoria'),
        'MedioSelectedPushCorporateDeal': () => import('./MedioSelectedPushCorporateDeal'),
        'MedioSelectedPushTemporadaAlta': () => import('./MedioSelectedPushTemporadaAlta')
    },
    data() {
        return {
            step: 'GO_TO_START',
            STEPS,
        }
    },
    computed: {
        ...mapGetters({
            email: 'email'
        }),
        canSeeSection() {
            return [
                'alberto.valencia@coppel.com',
                'luis.matus@coppel.com',
                'paola.leon@coppel.com',
                'antonio.alvarado@coppel.com'
            ].includes(this.email)
        }
    },
    methods: {
        selectStep(step) {
            this.step = step
        },
        handleSelectStep(step) {
            this.selectStep(step)
        }
    },
}
</script>


<style scoped>
    .full-height {
        height: 100% !important;
    }
</style>